<template>
  <div
    class="mbom-impact-test px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <SideBar class="sm:w-1/5 sm:order-first sm:mt-0 w-full order-last mt-4" />
      <div class="sm:w-4/5 w-full">
        <TitleComponent class="pt-8" title="IP Drop Test" size="large" />

        <div class="flex flex-wrap mt-8 p-4" style="background-color:#f7f5f4;">
          <div class="w-full">
            <TitleComponent class="py-8" title="Test Purpose" size="medium" />
            <p class="p-light">
              This test is used to determine if a finished or unfinished article
              can withstand a free-fall impact without producing any significant
              fire or explosion hazards. The test is not intended to evaluate
              whether the unit can withstand the actual impact. The article is
              dropped at an orientation in which it is most likely to function
              on impact. Three drops are made on identical units unless a
              decisive event (e.g. fire or explosion) occurs earlier. A test
              result is considered positive if evidence of initiation is
              identified. A rupture of the casing alone is not considered a
              positive result.
            </p>
            <p class="p-light mt-4">
              If an unfinished article fails this test, further testing is not
              required. The classification of that article is a 1.1, mass
              reaction hazard.
            </p>
          </div>
          <div class="w-full">
            <TitleComponent class="pt-8" title="Equivalence" size="medium" />
            <p class="p-light">
              This test is similar to
              <router-link class="link" to="/12-meter-drop"
                >Test 4 (b) (ii)</router-link
              >
              in the UN Manual of Tests and Criteria.
            </p>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full">
            <TitleComponent class="pt-8" title="Key Parameters" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Key Parameter</th>
                    <th>Objectives</th>
                    <th>Origin</th>
                    <th>Specs</th>
                  </tr>
                  <tr>
                    <td>Drop height to bottom of test unit</td>
                    <td>
                      Provide an impact to the unfinished article with
                      sufficient energy as liable to occur during normal
                      conditions in the process
                    </td>
                    <td>
                      12 meters is the potential drop height while loading a
                      cargo ship. NOTE: Approximate speed upon impact is 55
                      km/hr (34 mph).
                    </td>
                    <td>
                      Highest credible drop height: 12 meters (40 feet) if
                      transported on-site or in an elevator
                    </td>
                  </tr>
                  <tr>
                    <td>Number of trials</td>
                    <td>
                      Sufficient to give a reasonable level of confidence in the
                      reproducibility of the test results
                    </td>
                    <td></td>
                    <td>3 trials</td>
                  </tr>
                  <tr>
                    <td>Impact surface (construction and size)</td>
                    <td>
                      Provide a solid base with a reasonably smooth surface for
                      maximum effect that is large enough for free-fall impact
                      with associated variables
                    </td>
                    <td></td>
                    <td>
                      Example of a solid base is a steel plate, at least 75mm
                      (3-in) thick with a Brinell hardness not less than 200, on
                      a concrete foundation, 600mm (24-in) thick minimum; the
                      length and width of the impact surface not less than 1.5
                      times the unit being dropped
                    </td>
                  </tr>
                  <tr>
                    <td>Impact attitude</td>
                    <td>
                      Impact attitude should be such that will most likely
                      result in a reaction
                    </td>
                    <td></td>
                    <td>
                      TB 700-2 (2012): Dropped in three different orientations:
                      major axis vertical, nose down; major axis vertical, base
                      down; major axis horizontal
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <TitleComponent class="pt-8" title="Indicators" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Indicators</th>
                    <th>Detection Method</th>
                    <th>Assessment</th>
                  </tr>
                  <tr>
                    <td>Fire or explosion</td>
                    <td>Observation of test; video camera evidence</td>
                    <td>Fire or explosion [IP Division 1.1]</td>
                  </tr>
                  <tr>
                    <td>Rupture of the casing</td>
                    <td>Visual post-test inspection</td>
                    <td>
                      May be noted but is not considered a positive result by
                      itself.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap pb-6" style="background-color:#f7f5f4;">
          <div class="w-full md:w-1/2 md:pr-2">
            <TitleComponent
              class="pt-8"
              title="No Explosion (-) Example Photo"
              size="medium"
            />
            <a
              target="_blank"
              :href="require('../../assets/IPSeries/nogodroptest-min.jpg')"
            >
              <img
                class="m-auto"
                style="width:80%;"
                src="@/assets/IPSeries/nogodroptest-min.jpg"
              />
            </a>
          </div>
          <div class="w-full md:w-1/2 md:pl-2">
            <TitleComponent
              class="pt-8"
              title="No Explosion (-) Example Video"
              size="medium"
            />
            <iframe
              class="m-auto"
              src="https://player.vimeo.com/video/166132972?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo166132972"
              allow="autoplay; fullscreen"
              id="player_1"
              name="fitvid0"
            ></iframe>
          </div>
          <div class="w-full md:w-1/2 md:pr-2">
            <TitleComponent
              class="pt-8"
              title="Explosion (+) Example Video"
              size="medium"
            />
            <iframe
              class="m-auto"
              src="https://player.vimeo.com/video/166132970?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo166132970"
              allow="autoplay; fullscreen"
              id="player_2"
              name="fitvid1"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import SideBar from "@/components/IPSeriesSideBar";

export default {
  components: {
    TitleComponent,
    SideBar
  }
};
</script>

<style lang="scss"></style>
